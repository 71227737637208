import React from "react"
import AnilPokhriyal8 from "../images/16thMarch2023Event/images/agenda/anilPokhriyal8.jpg"
import op from "../images/newDesign/orientationPrograme/DSC_0426.jpg"
import { Link } from "gatsby"
export default function photoGallery() {
  return (
    <>
      <div className="mt-5 pt-5 mb-5 px-5 container-custom">
        <div className="">
          <h1 className="heading mb-5">Gallery</h1>
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
              style={{
                backgroundColor: "#f7f4e7",
              }}
            >
              <div class="card border-0 p-2 bg-transparent">
                <img
                  class="card-img-top"
                  src={AnilPokhriyal8}
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h4 class="fw-bold common-second-heading">
                    <Link
                      to="/esg-network-launch"
                      className="btn learn-more-btn  my-4"
                    >
                      Click to view the Gallery of Indian ESG Launch
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
              style={{
                backgroundColor: "#f7f4e7",
              }}
            >
              <div class="card border-0 p-2 bg-transparent">
                <img class="card-img-top" src={op} alt="Card image cap" />
                <div class="card-body">
                  <h4 class="fw-bold common-second-heading">
                    <Link
                      to="/orientation-gallery"
                      className="btn learn-more-btn  my-4"
                    >
                      Click to view the Gallery of Orientation Programme
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {/* <Link
            to="/speaker-form"
            className="btn learn-more-btn my-4 "
            style={{ marginRight: "5px" }}
          >
            Register as Speaker
          </Link> */}
        </div>
      </div>
    </>
  )
}
